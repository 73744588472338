<i18n src="@/locales/dental_care.json" />
<i18n src="@/locales/articles-card-content.json" />
<i18n src="@/locales/card-content-dental-care.json" />

<template>
  <div class="dental-care">
    <div class="header-background">
      <ImanHeader
        :title="$t('h1_title')"
      />
    </div>
    <section class="section">
      <div class="columns is-gapless is-multiline">
        <div
          v-for="content in cardContentFirstSet"
          :key="content.id"
          class="column is-half-tablet is-one-quarter-widescreen is-one-quarter-fullhd"
        >
          <ImanRoundedPictureBox
            :content="content"
            :service-class="roundedPicsClass"
          >
            {{ $t('more_info') }}
          </ImanRoundedPictureBox>
        </div>
      </div>
      <div class="columns is-gapless is-multiline">
        <div
          v-for="content in cardContentSecondSet"
          :key="content.id"
          class="column is-half-tablet is-one-quarter-widescreen is-one-quarter-fullhd"
        >
          <ImanRoundedPictureBox
            :content="content"
            :service-class="roundedPicsClass"
          >
            {{ $t('more_info') }}
          </ImanRoundedPictureBox>
        </div>
      </div>
    </section>
    <ImanH2>{{ $t('h2_title_1') }}</ImanH2>
    <section :class="mainBackgroundClass + ` section`">
      <ImanHomeSection :img="img[0]">
        <p>
          {{ $t('article_p1') }}
        </p>
      </ImanHomeSection>
    </section>
    <ImanH2>{{ $t('h2_title_2') }}</ImanH2>
    <section class="columns is-centered background-section">
      <div class="column is-8">
        <ImanCollapsableCard
          :title="$t('collapse_title_1')"
        >
          <p>
            {{ $t('collapse_p1') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_1')">
            {{ $t('more_info_1') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
        <ImanCollapsableCard
          :title="$t('collapse_title_2')"
        >
          <p>
            {{ $t('collapse_p2') }}
          </p>
          <ImanMoreInfo :path="$t('more_info_path_2')">
            {{ $t('more_info_2') }}
          </ImanMoreInfo>
        </ImanCollapsableCard>
      </div>
    </section>
    <ImanH2>{{ $t('h2_articles') }}</ImanH2>
    <ImanArticleSection :content-list="filteredArticlesCardContent" />
  </div>
</template>

<script>
  import ImanRoundedPictureBox from "@/components/ImanRoundedPictureBox";
  import ImanMoreInfo from "@/components/ImanMoreInfo";
  import ImanArticleSection from "@/components/ImanArticleSection";
  import {articlesCardContentFilterMixin} from "@/mixins/articles-card-content-filter-mixin";
  import {cardContentDentalCareMixin} from "@/mixins/card-content-dental-care-mixin";
  import {metaMixin} from "@/mixins/meta-mixin";

export default {
  name: 'DentalCare',
  components: {
    ImanMoreInfo,
    ImanArticleSection,
    ImanRoundedPictureBox
  },
  mixins: [
    articlesCardContentFilterMixin,
    cardContentDentalCareMixin,
    metaMixin
  ],
  data() {
    return {
      articlesCardContentId: [85, 612, 52, 53],
      roundedPicsClass: {
        overlay: 'overlay-half-circle show-overlay-half-circle',
        element: 'element show-element'
      },
      mainBackgroundClass: 'none-background',
      img: [
        {
          path: 'dental-care/qualite-soins-dentaires-bulgarie-suisse.jpg',
          position: 'right'
        }
      ]
    }
  },
  computed: {
    cardContentFirstSet () {
      return this.cardContentDentalCare.filter(content => content.id < 4)
    },
    cardContentSecondSet () {
      return this.cardContentDentalCare.filter(content => content.id > 3)
    },
    openGraphImgPath () {
      return 'https://anveli.dental' + require('../../assets/img/thumbnail/MINIATURE_3.0.png')
    },
  },
  created() {
    window.addEventListener('scroll', this.whenScroll)
  },
  destroyed() {
    window.removeEventListener('scroll', this.whenScroll)
  },
  methods: {
    whenScroll() {
      if (document.body.scrollTop > 1 || document.documentElement.scrollTop > 1) {
        this.mainBackgroundClass = 'none-background'
      }
      if (document.body.scrollTop > 1000 || document.documentElement.scrollTop > 1000) {
        this.mainBackgroundClass = 'fixed-background'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .header-background {
    @media only screen and (min-width: 768px) {
      /* tablet et ordinateurs */
      background-image: url("../../assets/img/dental-care/h1-soins-dentaires-d.jpg");
    }
    @media only screen and (max-width: 767px) {
      /* smartphone en mode paysage */
      background-image: url("../../assets/img/dental-care/h1-soins-dentaires-t.jpg");
    }
    @media only screen and (max-width: 767px) and (orientation: portrait) {
       /* smartphone en mode portait */
      background-image: url("../../assets/img/dental-care/h1-soins-dentaires-m.jpg");
    }
  }
</style>
