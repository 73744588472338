import { render, staticRenderFns } from "./DentalCare.vue?vue&type=template&id=beac3188&scoped=true"
import script from "./DentalCare.vue?vue&type=script&lang=js"
export * from "./DentalCare.vue?vue&type=script&lang=js"
import style0 from "./DentalCare.vue?vue&type=style&index=0&id=beac3188&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "beac3188",
  null
  
)

/* custom blocks */
import block0 from "@/locales/dental_care.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fdental-care%2FDentalCare.vue&external"
if (typeof block0 === 'function') block0(component)
import block1 from "@/locales/articles-card-content.json?vue&type=custom&index=1&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fdental-care%2FDentalCare.vue&external"
if (typeof block1 === 'function') block1(component)
import block2 from "@/locales/card-content-dental-care.json?vue&type=custom&index=2&blockType=i18n&issuerPath=%2Fopt%2Fbuild%2Frepo%2Fsrc%2Fviews%2Fdental-care%2FDentalCare.vue&external"
if (typeof block2 === 'function') block2(component)

export default component.exports