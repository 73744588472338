export const cardContentDentalCareMixin = {
  computed: {
    cardContentDentalCare () {
      let cardContentDentalCare = []
      switch (this.$i18n.locale) {
        case 'en':
          cardContentDentalCare = this.$i18n.messages.en.cardContentDentalCare
          break
        case 'ru':
          cardContentDentalCare = this.$i18n.messages.ru.cardContentDentalCare
          break
        default:
          cardContentDentalCare = this.$i18n.messages.fr.cardContentDentalCare
      }
      return Object.values(cardContentDentalCare)
        .filter(cardContent => cardContent)
    }
  }
}
